import _keyBy from "lodash/keyBy";
import _mapValues from "lodash/mapValues";
import "core-js/modules/es.array.push.js";
import common from '@mixins/common';
import { mapGetters } from 'vuex';
import * as assetsApi from '@api/assets';
import imageCompression from 'browser-image-compression';
import { ImagePreview } from 'vant';
import { getAllSpaceByProjectId, getAllSpaceByProjectld } from '@api/assets';
const productPropertyList = [{
  label: "易耗品",
  value: 1
}, {
  label: "低值品",
  value: 2
}, {
  label: "固定资产",
  value: 3
}];
const productPropertyMap = _mapValues(_keyBy(productPropertyList, i => i.value), j => j.label);
export default {
  name: 'AssetsCheckIndex',
  mixins: [common],
  computed: {
    ...mapGetters('user', ['userInfo', 'projectInfo'])
  },
  data() {
    return {
      projectId: '',
      projectName: '',
      spaceFullCode: '',
      spaceFullName: '',
      spaceType: '',
      spaceListShow: false,
      fieldNames: {
        text: 'spaceName',
        value: 'spaceId',
        children: 'children'
      },
      spaceId: '',
      spaceList: [],
      checkId: '',
      productList: [],
      page: 1,
      total: 0,
      pageSize: 10,
      currentItem: null,
      saveCheckReasonShow: false,
      reason: '',
      productPropertyMap,
      productListShow: false,
      productListGets: [],
      productListSearchKeyword: '',
      productListSearchPageNum: 1,
      productListSearchPageSize: 99,
      productListSearchTotal: 0,
      productListResultShow: false,
      currentProduct: {
        id: '',
        productName: '',
        productProperty: '',
        standard: '',
        productNo: '',
        count: 1,
        mode: 0,
        checks: {},
        dingDeptId: 0,
        dingDeptName: '',
        assetsTypeName: '',
        addImgType: 0,
        pictureUrls: [],
        standardDesId: '',
        standardDesName: ''
      },
      stockInImgList: [],
      gdListShow: false,
      gdList: [],
      deptListShow: false,
      deptList: [],
      checks: {},
      ownerDesListShow: false,
      ownerDesList: [],
      addOwnerDesDialogShow: false,
      addOwnerDesValue: '',
      standardDesListShow: false,
      standardDesList: [],
      addStandardDesDialogShow: false,
      addStandardDesValue: ''
    };
  },
  mounted() {
    this.applicationPerson = this.userInfo.nickName;
    let {
      dcProjectId,
      projectName
    } = this.projectInfo;
    this.projectId = dcProjectId;
    this.projectName = projectName;
    this.getSpaceList();
  },
  methods: {
    onPreview(url) {
      ImagePreview([url]);
    },
    getSpaceList() {
      assetsApi.getAllSpaceByProjectId(this.projectId).then(res => {
        let list = res.data.data || [];
        this.spaceList = this.arrayToTree(list);
        if (!this.spaceList.length) {
          this.$toast('暂无区域');
        } else {
          this.spaceListShow = true;
        }
      });
    },
    arrayToTree(data) {
      const map = new Map();
      const roots = [];
      data.forEach(nodeData => {
        const {
          spaceId,
          spaceName,
          spaceType,
          spaceFullCode,
          spaceFullName,
          projectId
        } = nodeData;
        const node = {
          spaceId,
          spaceName,
          spaceType,
          spaceFullCode,
          spaceFullName,
          projectId
        };
        map.set(spaceId, node);
      });
      data.forEach(nodeData => {
        const {
          spaceId,
          parentId
        } = nodeData;
        const node = map.get(spaceId);
        const parent = parentId ? map.get(parentId) : null;
        if (parent) {
          if (!parent.children) {
            parent.children = [];
          }
          parent.children.push(node);
        } else {
          roots.push(node);
        }
      });
      return roots;
    },
    spaceChange(e) {
      this.spaceFullName = e.selectedOptions[e.selectedOptions.length - 1].spaceFullName;
      this.spaceFullCode = e.selectedOptions[e.selectedOptions.length - 1].spaceFullCode;
      this.spaceType = e.selectedOptions[e.selectedOptions.length - 1].spaceType;
    },
    spaceFinish() {
      this.spaceListShow = false;
      this.getData();
    },
    onSelectSpace(item) {
      this.spaceFullCode = item.spaceFullCode;
      this.spaceFullName = item.spaceFullName;
      this.spaceType = item.spaceType;
      this.spaceListShow = false;
      this.getData();
    },
    getData() {
      assetsApi.getAddList({
        pageNum: this.page,
        pageSize: this.pageSize,
        projectId: this.projectId,
        spaceFullCode: this.spaceFullCode
      }).then(res => {
        this.total = res.data.count;
        this.productList = res.data.data;
      });
    },
    onAddItem() {
      this.currentProduct = {};
      this.productListSearchKeyword = '';
      this.productListGets = [];
      this.productListResultShow = false;
      this.productListShow = true;
    },
    onProductListSearch() {
      let params = {
        productName: this.productListSearchKeyword,
        pageNum: this.productListSearchPageNum,
        pageSize: this.productListSearchPageSize
      };
      assetsApi.getProductList(params).then(res => {
        this.productListGets = res.data.data;
        this.productListSearchTotal = res.data.count;
        this.productListResultShow = true;
      });
    },
    onProductListSearchPage() {
      this.onProductListSearch();
    },
    onProductListSelect(product) {
      this.currentProduct = {
        id: product.id,
        productName: product.productName,
        productProperty: product.productProperty,
        standard: product.standard,
        unit: product.unit,
        productNo: '',
        count: 1,
        mode: 0,
        checks: {},
        dingDeptId: 0,
        dingDeptName: '',
        assetsTypeName: product.assetsTypeName,
        addImgType: product.addImgType || 0,
        pictureUrls: [],
        standardDesId: '',
        standardDesName: ''
      };
      this.productListResultShow = false;
    },
    onProductSelectConfirm(action, done) {
      if (action === 'confirm') {
        if (!this.currentProduct.id) {
          this.$toast('未选择资产');
          done(false);
          return;
        }
        if (this.currentProduct.productProperty === 3 && !this.currentProduct.dingDeptId) {
          this.$toast('未选择编码部门');
          done(false);
          return;
        }
        if (this.currentProduct.addImgType == 1 && this.currentProduct.pictureUrls.length == 0) {
          this.$toast('未上传资产照片');
          done(false);
          return;
        }
        let data = {
          spaceFullCode: this.spaceFullCode,
          spaceName: this.spaceFullName,
          projectId: this.projectId,
          projectName: this.projectName,
          productId: this.currentProduct.id,
          productNo: '',
          dingDeptId: 0,
          count: this.currentProduct.count,
          pictureUrl: this.currentProduct.pictureUrls.length ? this.currentProduct.pictureUrls[0].url : '',
          productName: this.currentProduct.productName,
          productProperty: this.currentProduct.productProperty,
          unit: this.currentProduct.unit,
          standard: this.currentProduct.standard,
          standardDesId: this.currentProduct.standardDesId,
          standardDesName: this.currentProduct.standardDesName
        };
        if (this.currentProduct.productProperty === 3) {
          data.dingDeptId = this.currentProduct.dingDeptId;
          data.ownerType = this.currentProduct.ownerType;
          data.ownerTypeName = this.currentProduct.ownerTypeName;
        }
        assetsApi.addAsset(data).then(res => {
          done();
          this.currentProduct = {};
          this.productListSearchKeyword = '';
          this.productListGets = [];
          this.productListResultShow = false;
          this.productListShow = false;
          this.getData();
          let gdList = res.data.data && res.data.data.detailList;
          if (gdList && gdList.length) {
            this.gdList = gdList;
            this.gdListShow = true;
          } else {
            this.$toast('添加成功');
          }
        }).catch(e => {
          done(false);
        });
      } else {
        done();
      }
    },
    onGdListConfirm() {
      this.gdListShow = false;
    },
    onDeptListShow() {
      assetsApi.getDingDeptList().then(res => {
        if (res.data.data) {
          this.deptList = res.data.data;
        }
      });
      this.deptListShow = true;
      this.$nextTick(() => {
        let value = this.currentProduct.dingDeptId;
        let selectIndex = this.deptList.findIndex(i => i.dingDeptId === value);
        this.$refs.deptList.setIndexes([selectIndex]);
      });
    },
    onDeptListSelect(item) {
      this.currentProduct.dingDeptId = item.dingDeptId;
      this.currentProduct.dingDeptName = item.dingDeptName;
      this.deptListShow = false;
    },
    afterFileRead(item) {
      let up = this.$toast.loading({
        forbidClick: true,
        duration: 0
      });
      imageCompression(item.file, {
        maxSizeMB: 0.2,
        initialQuality: 0.7
      }).then(file => {
        assetsApi.fileUpload(item).then(res => {
          this.$toast.clear();
          const cb = res.data;
          if (cb.code === 200) {
            item.fileType = 1;
            item.url = cb.data.url;
            item.fileUrl = cb.data.url;
            item.status = 'success';
            item.message = '上传成功';
          } else {
            item.status = 'failed';
            item.message = '上传失败';
          }
        }).catch(e => {
          this.$toast.clear();
          item.status = 'failed';
          item.message = '上传失败';
        });
      }).catch(() => {
        this.$toast.clear();
        item.status = 'failed';
        item.message = '上传失败';
      });
    },
    onOwnerDesListShow() {
      if (!this.currentProduct.id) {
        this.$toast('请先选择商品');
        return;
      }
      this.getOwnerDesList();
      this.ownerDesListShow = true;
    },
    getOwnerDesList() {
      assetsApi.getStandardDesList({
        sourceType: 'ownerType',
        sourceId: -1
      }).then(res => {
        this.ownerDesList = res.data.data;
      });
    },
    onOwnerDesListSelect(item) {
      this.currentProduct.ownerType = item.id;
      this.currentProduct.ownerTypeName = item.name;
      this.ownerDesListShow = false;
    },
    onAddOwnerDes() {
      this.addOwnerDesValue = '';
      this.addOwnerDesDialogShow = true;
    },
    onAddOwnerDesCancel() {
      this.addOwnerDesDialogShow = false;
    },
    onAddOwnerDesSubmit(action, done) {
      if (action === 'confirm') {
        if (this.addOwnerDesValue == '') {
          this.$toast('请输入名称');
          done(false);
          return;
        }
        assetsApi.addStandardDes({
          sourceId: -1,
          sourceType: 'ownerType',
          name: this.addOwnerDesValue
        }).then(res => {
          done();
          this.$toast('添加成功');
          this.getOwnerDesList();
        }).catch(() => {
          done(false);
        });
      } else {
        done();
      }
    },
    onStandardDesListShow() {
      if (!this.currentProduct.id) {
        this.$toast('请先选择商品');
        return;
      }
      this.getStandardDesList();
      this.standardDesListShow = true;
    },
    getStandardDesList() {
      assetsApi.getStandardDesList({
        sourceType: 'ppggxq',
        sourceId: this.currentProduct.id
      }).then(res => {
        this.standardDesList = res.data.data;
      });
    },
    onStandardDesListSelect(item) {
      this.currentProduct.standardDesId = item.id;
      this.currentProduct.standardDesName = item.name;
      this.standardDesListShow = false;
    },
    onAddStandardDes() {
      this.addStandardDesValue = '';
      this.addStandardDesDialogShow = true;
    },
    onAddStandardDesCancel() {
      this.addStandardDesDialogShow = false;
    },
    onAddStandardDesSubmit(action, done) {
      if (action === 'confirm') {
        if (this.addStandardDesValue == '') {
          this.$toast('请输入名称');
          done(false);
          return;
        }
        assetsApi.addStandardDes({
          sourceId: this.currentProduct.id,
          sourceType: 'ppggxq',
          name: this.addStandardDesValue
        }).then(res => {
          done();
          this.$toast('添加成功');
          this.getStandardDesList();
        }).catch(() => {
          done(false);
        });
      } else {
        done();
      }
    }
  }
};